import React, { useEffect, useRef, useState } from 'react';
import 'ol/ol.css';
import { Map as OlMap, View } from 'ol';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import QRScanner from './QRScanner';
import Question from './Question';

interface PointData {
  id: string;
  coords: [number, number];
  question: string;
  answers: string[];
}

interface MapComponentProps {
  onGameFinish: () => void;
}

const MapComponent: React.FC<MapComponentProps> = ({ onGameFinish }) => {
  const mapElement = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<OlMap | null>(null);
  const [points, setPoints] = useState<PointData[]>([]);
  const [currentPoint, setCurrentPoint] = useState<PointData | null>(null);

  useEffect(() => {
    const initialMap = new OlMap({
      target: mapElement.current as HTMLElement,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([37.618423, 55.751244]),
        zoom: 12,
      }),
    });
    setMap(initialMap);

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchCurrentPoint(user.uid, initialMap);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchCurrentPoint = async (userId: string, mapInstance: OlMap) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data();
      let nextPointId = 'point1';

      if (userData && userData.progress && userData.progress.length > 0) {
        const lastProgress = userData.progress[userData.progress.length - 1];
        nextPointId = lastProgress.nextPointId;
      }

      const pointDoc = await getDoc(doc(db, 'points', nextPointId));
      const pointData = pointDoc.data() as PointData;
      setCurrentPoint(pointData);
      setPoints([pointData]);

      addPointsToMap([pointData], mapInstance);
    } catch (error) {
      console.error('Ошибка получения точки', error);
    }
  };

  const addPointsToMap = (pointsData: PointData[], mapInstance: OlMap) => {
    const features = pointsData.map((pointData) => {
      const feature = new Feature({
        geometry: new Point(fromLonLat(pointData.coords)),
        name: `Point ${pointData.id}`,
      });
      feature.setStyle(
        new Style({
          image: new Icon({
            src: '/marker.png',
            scale: 0.05,
          }),
        })
      );
      return feature;
    });

    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    mapInstance.addLayer(vectorLayer);
  };

  const handleNextPoint = async (nextPointId: string | null) => {
    console.log('pointDoc', nextPointId);
    if (nextPointId && map) {
      try {
        const pointDoc = await getDoc(doc(db, 'points', nextPointId));
        if (pointDoc.exists()) {
          const pointData = pointDoc.data() as PointData;
          setCurrentPoint(pointData);
          setPoints((prevPoints) => [...prevPoints, pointData]);
          addPointsToMap([pointData], map);
        } else {
          alert('Игра завершена!');
          onGameFinish();
        }
      } catch (error) {
        console.error('Ошибка получения следующей точки', error);
      }
    } else {
      alert('Вы завершили игру!');
      onGameFinish();
    }
  };

  return (
    <div>
      <div ref={mapElement} style={{ width: '100%', height: '500px' }}></div>
      {currentPoint && <Question pointData={currentPoint} />}
      <QRScanner onAnswer={handleNextPoint} />
    </div>
  );
};

export default MapComponent;
