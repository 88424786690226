import React, { useState } from 'react';
import Register from './Register';
import MapComponent from './MapComponent';
import Results from './Results';

const App: React.FC = () => {
  const [registered, setRegistered] = useState<boolean>(false);
  const [gameFinished, setGameFinished] = useState<boolean>(false);

  const handleRegister = () => {
    setRegistered(true);
  };

  const handleGameFinish = () => {
    setGameFinished(true);
  };

  if (!registered) {
    return <Register onRegister={handleRegister} />;
  } else if (!gameFinished) {
    return <MapComponent onGameFinish={handleGameFinish} />;
  } else {
    return <Results />;
  }
};

export default App;
