import React from 'react';
import {QRCodeCanvas, QRCodeSVG} from 'qrcode.react';

interface QuestionProps {
  pointData: {
    id: string;
    question: string;
    answers: string[];
  };
}

const Question: React.FC<QuestionProps> = ({ pointData }) => {
  return (
    <div>
      <h2>{pointData.question}</h2>
      {pointData.answers.map((answer, index) => (
        <div key={index}>
          <p>{answer}</p>
          <QRCodeSVG minVersion={6} value={`${'point'+(index+1)}`} />
          {/*<QRCodeSVG minVersion={6} value={`${pointData.id}_${index}`} />*/}
        </div>
      ))}
    </div>
  );
};

export default Question;
