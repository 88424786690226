import React, {useState} from 'react';
import {Scanner} from '@yudiel/react-qr-scanner';
import {getFunctions} from 'firebase/functions';

interface QRScannerProps {
  onAnswer: (nextPointId: string | null) => void;
}

const QRScanner: React.FC<QRScannerProps> = ({onAnswer}) => {
  const [error, setError] = useState<string | null>(null);

  const handleScan = async (data: any | null) => {
    if (data) {
      try {
        const functions = getFunctions();
        // const checkAnswer = httpsCallable(functions, 'checkAnswer');
        // const result = await checkAnswer({ answerCode: data });
        const result = {data: {correct: true, nextPointId: data[0].rawValue}};

        if(result.data.nextPointId === 'point1') {
          result.data.nextPointId = 'point2';
        } else if(result.data.nextPointId === 'point2') {
          result.data.nextPointId = 'point3';
        } else {
          result.data.nextPointId = null;
        }

        const {correct, nextPointId} = result.data as { correct: boolean; nextPointId?: any };
        if (correct) {
          onAnswer(nextPointId);
        } else {
          alert('Ответ неверный. Попробуйте снова.');
        }
      } catch (err) {
        console.error('Ошибка при проверке ответа', err);
        setError('Ошибка при проверке ответа' + err);
      }
    }
  };

  const handleError = (err: any) => {
    setError('Ошибка сканера');
    console.error(err);
  };

  return (
    <div>
      <Scanner
        constraints={{facingMode: 'environment'}}
        onScan={(result) => {
          if (result) {
            handleScan(result);
          } else {
            handleError('No QR code found in the camera view');
          }
        }}
      />
      {error && <p>{error}</p>}
    </div>
  );
};

export default QRScanner;
