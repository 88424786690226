import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

interface UserResult {
  name: string;
  correctAnswers: number;
  totalTime: number;
}

const Results: React.FC = () => {
  const [results, setResults] = useState<UserResult[]>([]);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const resultsData: UserResult[] = [];

        usersSnapshot.forEach((doc) => {
          const data = doc.data();
          const correctAnswers = data.progress.filter((p: any) => p.correct).length;
          const startTime = data.startTime;
          const endTime = data.endTime || Date.now();
          const totalTime = endTime - startTime;
          resultsData.push({
            name: data.name,
            correctAnswers,
            totalTime,
          });
        });

        resultsData.sort(
          (a, b) =>
            b.correctAnswers - a.correctAnswers || a.totalTime - b.totalTime
        );

        setResults(resultsData);
      } catch (error) {
        console.error('Ошибка получения результатов', error);
      }
    };
    fetchResults();
  }, []);

  return (
    <div>
      <h2>Результаты</h2>
      <table>
        <thead>
        <tr>
          <th>Имя</th>
          <th>Правильные ответы</th>
          <th>Время (сек)</th>
        </tr>
        </thead>
        <tbody>
        {results.map((userResult, index) => (
          <tr key={index}>
            <td>{userResult.name}</td>
            <td>{userResult.correctAnswers}</td>
            <td>{(userResult.totalTime / 1000).toFixed(2)}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default Results;
